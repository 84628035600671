import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      @import url('https://fonts.googleapis.com/css2?family=Caveat&family=Bungee+Inline&family=Luckiest+Guy&family=Pacifico&display=swap');
      @font-face {
        font-family: 'Bungee Inline';
        font-style: normal;
        font-weight: 100;
        font-display: swap;
      }
      @font-face {
        font-family: 'Luckiest Guy';
        font-style: normal;
        font-weight: 100;
        font-display: swap;
      }
      @font-face {
        font-family: 'Caveat';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
      }
      `}
  />
)

export default Fonts