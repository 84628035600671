import {Link} from "@chakra-ui/react";
import * as React from "react";

export function MenuItem({children, href}: {children: string, href: string}) {

    return (
        <Link
            color='yellow'
            pt="1px"
            pl="1px"
            pb="0px"
            pr="0px"
            _hover={{pt: '0px', pl: '0px', pb: '1px', pr: '1px', textShadow: '3px 3px 0px green'}}
            mt='4px'
            mb='4px'
            transition='1s'
            textShadow='2px 2px 0px green'
            fontFamily='Luckiest Guy'
            fontSize='30px'
            _dark={{
                color: 'green',
                textShadow: '2px 2px 5px yellow'
            }}
            href={href}
        >
            {children}
        </Link>
    )

}