import {Box, Collapse, HStack, IconButton, Stack, useDisclosure,} from "@chakra-ui/react"
import {MenuItem} from "./menu-item";
import {ColorModeSwitcher} from "./color-mode-switcher";
import {GiHamburgerMenu, GrClose} from "react-icons/all";


export function MenuItems() {
    return (
        <>
            <MenuItem href="/">Home</MenuItem>
            <MenuItem href="/contact">Contact</MenuItem>
        </>
    )
}

export function Menu() {
    const {isOpen, onOpen, onClose} = useDisclosure();

    return (
        <>
            <IconButton
                _dark={{background: '#00000077'}}
                background={'#ffffff88'}
                position={"fixed"}
                top={0}
                p={'12px'}
                size={'md'}
                icon={isOpen ? <GrClose/> : <GiHamburgerMenu/>}
                aria-label={'Open Menu'}
                display={{md: 'none'}}
                onClick={isOpen ? onClose : onOpen}
            />
            <HStack
                px={'auto'}
                gap={5}
                w="full"
                justifyContent='center' zIndex={100} position='fixed' top={0}
                _dark={{background: '#000000cc'}}
                background={'#ffffffcc'}
                display={{base: 'none', md: 'flex'}}
            >
                <MenuItems/>
            </HStack>
            <Collapse in={isOpen}>
                <Box
                    overflow={"hidden"}
                    p={4}
                    zIndex={100}
                    display={{md: 'none'}}
                    _dark={{background: '#000000cc'}}
                    background={'#ffffffcc'}
                    transition={'1s'}
                    position={'fixed'}
                    top={'42px'}

                >
                    <Stack as={'nav'} spacing={2}>
                        <MenuItems/>
                    </Stack>
                </Box>
            </Collapse>
        </>
    )
}

