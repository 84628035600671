import * as React from "react"
import {Box, ChakraProvider, extendTheme,} from "@chakra-ui/react"
import {Home} from "./Home";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Gigs} from "./Gigs";
import Fonts from "./Fonts";
import './components/player.css'
import {Menu} from "./components/menu";
import {Media} from "./Media";
import {Contact} from "./Contact";
import {useEffect} from "react";

const theme = extendTheme({
    colors: {
        yellow: '#eddc01ff',
        green: '#186226ff'
    },
    components: {
        Heading: {
            baseStyle: {
                fontFamily: 'Luckiest Guy',
                color: 'green',
                display: 'block',
                py: 4
            }
        }
    }

})

export const App = () => {
    useEffect(() => {
        document.title = 'Banda ¡Carumba! 💃🪘🎺🪗';
    }, []);

    return (
        <ChakraProvider theme={theme}>
            <Fonts/>
            <Box>
                <BrowserRouter>
                    <Routes>
                        <Route path='/' element={<Home/>}/>
                        <Route path='/contact' element={<Contact/>}/>
                    </Routes>
                </BrowserRouter>
                <Menu />
            </Box>
        </ChakraProvider>
    )
}
