import {Box, Container, Heading, IconButton, Link} from "@chakra-ui/react";
import * as React from "react";
import {ReactElement} from "react";
import {Title} from "./components/title";
import {
    AiFillFacebook,
    AiFillInstagram,
    AiFillPhone, AiFillPicture,
    AiFillYoutube,
    AiTwotonePicture,
    BiEnvelope, ImFileZip
} from "react-icons/all";


export function ContactLink({link, title, icon}: { link: string, title: string, icon: ReactElement }) {
    return (
        <Box>
            <Link href={link} color={'green'} fontWeight={'bold'}>
                <IconButton aria-label={title} variant="ghost" icon={icon} mr={2} color={'green'}/>
                {title}
            </Link>
        </Box>
    )
}

export function Contact() {

    return (
        <Container maxW={'1500px'} textAlign={'center'} mt={'12'}>
            <Title>
                Contact
            </Title>
            <Box justifyContent={'center'}>
                <Heading variant={'h3'}>Boekingen</Heading>

                <ContactLink
                    link={'mailto:booking@carumba.nl'}
                    title={'booking@carumba.nl'}
                    icon={<BiEnvelope size={24}/>}
                />
                <ContactLink
                    link={'phone://+31 6 53 53 59 95'}
                    title={'+31 6 53 53 59 95'}
                    icon={<AiFillPhone size={24}/>}
                />

                <Heading variant={'h3'}>Socials</Heading>

                <ContactLink
                    link={'https://www.facebook.com/bandacarumba/'}
                    title={'/bandacarumba'}
                    icon={<AiFillFacebook size={24}/>}
                />
                <ContactLink
                    link={'https://www.instagram.com/bandacarumba/'}
                    title={'/bandacarumba'}
                    icon={<AiFillInstagram size={24}/>}
                />
                <ContactLink
                    link={'https://www.youtube.com/@bandacarumba3880'}
                    title={'@bandacarumba3880'}
                    icon={<AiFillYoutube size={24}/>}
                />
                <Heading variant={'h3'}>Promo</Heading>
                <ContactLink
                    link={'/images/Banda_Carumba_4095_LR_Logo.jpg'}
                    title={'Promo foto Low Res (3.5MB)'}
                    icon={<AiFillPicture size={24}/>}
                />
                <ContactLink
                    link={'/images/Banda_Carumba_4095_HR_Logo.jpg'}
                    title={'Promo foto High Res (79MB)'}
                    icon={<AiFillPicture size={24}/>}
                />
                <ContactLink
                    link={'/images/Banda_Carumba_Promo_LR.zip'}
                    title={'Promo kit Low Res (13MB)'}
                    icon={<ImFileZip size={24}/>}
                />
                <ContactLink
                    link={'/images/Banda_Carumba_Promo_HR.zip'}
                    title={'Promo kit High Res (292MB)'}
                    icon={<ImFileZip size={24}/>}
                />
            </Box>
        </Container>
    )

}