import {Box, Heading, Text, VStack} from "@chakra-ui/react";
import * as React from "react";
import ReactPlayer from 'react-player'

export function Home() {

    // const videoUrl = '/videos/gouda.mp4'
    const videoUrl = '/images/promo.mp4'

    return (
        <Box>
            <ReactPlayer
                position={"fixed"}
                height={0}
                className={'bg-player'}
                playing={true}
                autoPlay={true}
                controls={false}
                loop={true}
                muted={true}
                object-fit='cover'
                playbackRate={0.5}
                url={videoUrl}
                overflow={"hidden"}
            />
            <VStack spacing={[0, 1, 2, 4]} position='relative' py={'72px'} overflow={'auto'}>
                <Heading
                    fontFamily="Bungee Inline"
                    fontWeight="normal"
                    fontSize={[20, 24, 30, 40]}
                    letterSpacing={[4, 4, 6, 8]}
                    color="yellow"
                    textShadow={'4px 4px 0px green'}
                >
                    &middot; B &middot; a &middot; n &middot; d &middot; a &middot;
                </Heading>
                <Heading
                    fontFamily="Luckiest Guy"
                    fontWeight="normal"
                    fontSize={[50, 60, 70, 80]}
                    letterSpacing="8px"
                    color="yellow"
                    textShadow={'4px 4px 0px green'}
                >
                    ¡Carumba!
                </Heading>
                <Box>
                    <Box
                        color="yellow"
                        fontSize={[20, 24, 28, 36]}
                        maxW='750px'
                        p="8"
                        background={'#006600aa'}
                        borderRadius={12} fontFamily={'Caveat'}>
                        Rumba, cumbia en latin. Spaans, Catalaans, Frans en Cubaans. Als het corazon heeft, kan Banda ¡Carumba! het spelen. Drie percussionisten, twee blazers, een contrabassist, accordeonist, gitarist en zanger krijgen overal de heupen aan het draaien. Banda ¡Carumba!  werd eind 2020 opgericht in Leiden en speelt een spannende mix van stijlen in een eigen jasje en met grote flair. Het gezelschap herbergt dan ook vele jaren Leidse muziekgeschiedenis, met muzikanten die hun sporen hebben verdiend in bands als TT Togs, Biguine Balade, Toy Factory, Gratis Bier, Les Zazous, Klavan Gadje en Helter Shelter. Op Oerol worden ze inmiddels vast geboekt en zelfs tijdens de Museumnacht in Leiden laten ze de zon schijnen. Met Banda ¡Carumba! is het altijd zomer.

                    </Box>
                    <Box
                        color="yellow"
                        fontSize={[14, 18, 20, 24]}
                        maxW='750px'
                        p="8"
                        mt={8}
                        background={'#006600aa'}
                        borderRadius={12}
                        fontFamily={'Luckiest Guy'}
                    >

                        Hendrik Kamlade - Guitarra, voz
                        <br/>
                        Laurens van Heesch - Trompeta, voz
                        <br/>
                        Loek van Gent - Acordeón
                        <br/>
                        Ronald Willemsen - Contrabajo
                        <br/>
                        Miguel Cabral - Percussión
                        <br/>
                        André de Jager - Percussión
                    </Box>
                </Box>
            </VStack>
        </Box>

    )

}