import {Box, Heading} from "@chakra-ui/react";
import * as React from "react";

export function Title({children}: {children: string}) {

    return (
        <Box
            backgroundColor='yellow'
            py="8px"
            mt='12px'
            mb='20px'
            color='green'
            borderRadius={'36px'}
            shadow='3px 3px 0px green'
            _dark={{
                shadow: '0px 0px 8px yellow',
                color: 'yellow',
                backgroundColor: 'green',
                border: '1px solid yellow'

            }}
        >
            <Heading variant={'main'} fontFamily='Bungee Inline'>
                {children}
            </Heading>
        </Box>
    )

}